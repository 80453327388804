@font-face {
  font-family: 'NextExitRound';
  src: url('assets/fonts/NextExitRound-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NextExitRound';
  src: url('assets/fonts/NextExitRound-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

:root {
  color-scheme: light only;
}

* {
  padding: 0;
  margin: 0;
  font-size: 22px;
  font-weight: lighter;
  box-sizing: border-box;

  /* remove android background on touch */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  direction: rtl;
}

body {
  font-family: "NextExitRound", Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, option {
  font-family: "NextExitRound", Arial;
  font-size: 24px;
}

select:focus {
  outline: none;
}